
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import SalesOrder from "@/types/salesorder";
import Card from "primevue/card";
import SalesDialog from "@/components/Sales/SalesDialog.vue";
import ProductionStage from "@/components/Manufacturing/ProductionStage.vue";

export default defineComponent({
  name: "Bulk",
  components: {
    ProductionStage,
    SalesDialog,
    Card,
  },
  computed: {
    ...mapState(["shipControl"]),
    ...mapGetters({}),
  },

  data() {
    return {
      items: [] as Array<SalesOrder>,
      selectedSalesID: null,
      selectedCustomer: null,
      selectedSale: null,
      selectedRow: null,
      order: false,
      orderRecordsBy: "",
      selectedSalesStatus: null,
      readOnly: true,
      sortOrder: 1,
      sortField: "",
      first: 0,
      rows: 10,
      page: 1,
      isLoadingSales: false,
      status: [
        { status: "New", initial: "N" },
        { status: "Back Order", initial: "B" },
        { status: "Closed", initial: "C" },
      ],
      showSalesDialog: false,
      salesOrder: null as unknown,
      isResultView: true as boolean,
      lastUpdated: Date.now(),
      filename: "SOH.WEB",
      productionStage: ["BULK"],
      pageTitle: "Bulk Left Over",
      dynamicColumns: [
        { field: "FORMULA_PART_VIEW", header: "Formula Part No", input: true },
        { field: "BULK_GALLONS", header: "Gal", input: true },
        { field: "PROD_DESC_VIEW", header: "Product Description", input: true },
        {
          field: "BULK_LAST_USED_DATE",
          header: "Last Day Used",
          input: false,
          calendar: true,
        },
        {
          field: "BULK_EXP_DATE",
          header: "Exp Date",
          input: false,
          calendar: true,
        },
        { field: "BULK_BATCH", header: "Batch Code", input: true },
      ],
    };
  },
  methods: {
    ...mapActions({}),
    openSales(edit: boolean) {
      this.showSalesDialog = true;
      this.readOnly = edit;
    },
    isResultViewChange(event: boolean) {
      this.isResultView = event;
    },
    isDataUpdated(event: number) {
      this.lastUpdated = event;
    },
  },
});
